jQuery(document).ready(function($) {
	if( $('.technology-partners .slick').length ){
		$('.technology-partners .slick').slick({
			dots: false,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 5000,
			pauseOnHover: false,
			infinite: true,
			draggable: true,
			slidesToShow: 8,
			slidesToScroll: 8,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 7,
						slidesToScroll: 7
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 5
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				}
			]
		});
	}
});
