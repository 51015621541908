var tl1;

jQuery(document).ready(function($) {
	if( $('.cta-contact-us').length && isBreakpoint('lg') ){
		$(window).scroll(function(event){
			var scrolled = $(this).scrollTop() + $(window).height();

			if( scrolled > $('.cta-contact-us-stopper').offset().top ){
				if( $('.cta-contact-us').css('position')!='absolute' ){
					$('.cta-contact-us').css({
						position: 'absolute',
						top: $('.cta-contact-us-stopper').offset().top - 140,
						bottom: 'auto'
					});
				}
			}
			else{
				$('.cta-contact-us').css({
					position: 'fixed',
					top: 'auto',
					bottom: 120,
					left: 0
				});
			}
		});
	}

	var windowWidth = $(window).width(),
		windowHeight = $(window).height(),
		tweenTimeout,
		lineTween,
		designTween,
		developmentTween,
		marketingTween,
		iotTween;

	$(window).resize(function(){
		windowWidth = $(window).width();
		windowHeight = $(window).height();

		if( $('.home-top .divisions').length ){
			lineTween.time(0);
			designTween.time(0);
			developmentTween.time(0);
			marketingTween.time(0);
			iotTween.time(0);

			lineTween.kill();
			designTween.kill();
			developmentTween.kill();
			marketingTween.kill();
			iotTween.kill();


			clearTimeout(tweenTimeout);
			tweenTimeout = setTimeout(function(){
				lineAnimation();
			}, 1000);
		}
	});

	lineAnimation();

	$('.home-top .divisions .division').hover(function(){
		if( !detectIE() && isBreakpoint('lg') ){
			lineTween.pause();
			designTween.pause();
			developmentTween.pause();
			marketingTween.pause();
			iotTween.pause();
		}
	}, function(){
		if( !detectIE() && isBreakpoint('lg') ){
			lineTween.play();
			designTween.play();
			developmentTween.play();
			marketingTween.play();
			iotTween.play();
		}
	});

	function lineAnimation(){
		if( !detectIE() && isBreakpoint('lg') ){
			if( $('.home-top .divisions').length ){
				var line = document.getElementById("line_1");
				
				lineTween = TweenMax.to(line, 4,{
					morphSVG:"#line_2",
					repeat:-1,
					yoyo:true,
					ease:Power1.easeInOut
				});


				var design = document.getElementById("obj-1"),
					designTweenPos = parseInt( ( windowWidth * 227 ) / 1565 );

				designTween = TweenMax.to(design, 4,{
					y:designTweenPos,
					repeat:-1,
					yoyo:true,
					ease:Power1.easeInOut
				});

				var development = document.getElementById("obj-2"),
					developmentTweenPos = -1 * parseInt( ( windowWidth * 85 ) / 1565 );

				developmentTween = TweenMax.to(development, 4,{
					y:developmentTweenPos,
					repeat:-1,
					yoyo:true,
					ease:Power1.easeInOut
				});

				var marketing = document.getElementById("obj-3"),
					marketingTweenPos = -1 * parseInt( ( windowWidth * 275 ) / 1565 );

				marketingTween = TweenMax.to(marketing, 4,{
					y:marketingTweenPos,
					repeat:-1,
					yoyo:true,
					ease:Power1.easeInOut
				});

				var iot = document.getElementById("obj-4"),
					iotTweenPosY = -1 * parseInt( ( windowWidth * 15 ) / 1565 ),
					iotTweenPosx = parseInt( ( windowHeight * 20 ) / 324 );

				iotTween = TweenMax.to(iot, 4,{
					x:iotTweenPosx,
					y:iotTweenPosY,
					repeat:-1,
					yoyo:true,
					ease:Power1.easeInOut
				});
			}
		}
	}

	var text = $('.home-top .intro .text-effect'),
		text_1 = text.find('.txt-1'),
		text_2 = text.find('.txt-2'),
		texts = text.find('.txt');

	var maxHeight = Math.max.apply(null, texts.map(function(){ return jQuery(this).height(); }).get());
	var maxWidth = Math.max.apply(null, texts.map(function(){ return jQuery(this).width(); }).get());

	text.height( maxHeight );
	text.width( maxWidth );

	tl1 = new TimelineMax({
		paused:true,
		onComplete:function() {
			var $this = this;
			setTimeout(function(){
				$this.restart();
			}, 2000);
		}
	});

	var prevElem;
	texts.each(function(index){
		var $this = $(this);
		
		var animDelay = 2;
		if( index===0 ){
			animDelay = 0;
		}

		if( prevElem ){
			tl1.set( prevElem, {
				delay: animDelay,
				opacity: 0
			});
		}

		tl1.staggerFrom( $this, 1, {
			opacity: 0,
			y:100
		});

		prevElem = $this;
	});

	function homeTextAnim(){
		var animTimeout_1, animTimeout_2;
		// hide text_1
		animTimeout_1 = setTimeout(function(){
			TweenMax.set(text_1, {
				//opacity:0,
				y:100,
				onComplete: function(){
					// show text_2
					TweenMax.set(text_2, {
						opacity:1,
						y:100,
						onComplete: function(){
							// hide text_2
							TweenMax.to(text_2, 0.5,{
								y:0,
								onComplete: function(){
									animTimeout_2 = setTimeout(function(){
										TweenMax.set(text_2, {
											//opacity:0,
											y:100,
											onComplete: function(){
												// show text_1
												TweenMax.set(text_1, {
													opacity:1,
													y:100,
													onComplete: function(){
														TweenMax.to(text_1, 0.5,{
															y:0,
															onComplete: homeTextAnim
														});
													}
												});
											}
										});
									}, 4000);
								}
							});
						}
					});
				}
			});
		}, 4000);
	}
	// homeTextAnim();

	if( $('.our-works .works-list').length ){
		if( isBreakpoint('sm') || isBreakpoint('md') || isBreakpoint('lg') ){
			$('.our-works .works-list .item').each(function(){
				if( !$(this).hasClass('animated') ){
					TweenMax.set($(this),{
						y: 100,
						opacity: 0
					});

					$(this).waypoint({
						handler: function(direction) {
							var $this = $(this.element);

							$this.addClass('animated');

							TweenMax.to($this, 1,{
								y: 0,
								opacity: 1
							});
						},
						offset: '90%'
					});
				}
			});
		}
	}

	if( $('.our-activity .activity-list').length ){
		if( isBreakpoint('sm') || isBreakpoint('md') || isBreakpoint('lg') ){
			$('.our-activity .activity-list .item').each(function(){
				if( !$(this).hasClass('animated') ){
					TweenMax.set($(this),{
						y: 100,
						opacity: 0
					});

					$(this).waypoint({
						handler: function(direction) {
							var $this = $(this.element);

							$this.addClass('animated');

							TweenMax.to($this, 1,{
								y: 0,
								opacity: 1
							});
						},
						offset: '90%'
					});
				}
			});
		}
	}

	if( detectIE() ){
		$('.home-top .divisions svg').hide();
		$('.home-top .divisions .svg-line-fallback').removeClass('hidden');
	}

	$('.activity-list .toggler').on('click', function(e){
		e.preventDefault();

		var activeClass = 'active',
			$this = $(this);

		//if( isBreakpoint('xs') ){
			$('.activity-list .item.' + activeClass).removeClass(activeClass);
			$this.closest('.item').addClass(activeClass);

			if( isBreakpoint('lg') ){
				$('html, body').animate({
					scrollTop: parseInt($this.closest('.item').offset().top) - 150
				}, 250);
			}
			else{
				$('html, body').animate({
					scrollTop: parseInt($this.closest('.item').offset().top) - 100
				}, 250);
			}
		// }
	});
	homeActivity();

	$('.our-notes .slick').slick({
		dots: false,
		arrows: true,
		autoplay: false,
		autoplaySpeed: 5000,
		pauseOnHover: false,
		infinite: true,
		draggable: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	if( $('.our-notes .notes-list').length ){
		if( isBreakpoint('sm') || isBreakpoint('md') || isBreakpoint('lg') ){
			$('.our-notes .notes-list .note-item').each(function(){
				if( !$(this).hasClass('animated') ){
					TweenMax.set($(this),{
						y: 100,
						opacity: 0
					});

					$(this).waypoint({
						handler: function(direction) {
							var $this = $(this.element);

							$this.addClass('animated');

							TweenMax.to($this, 1,{
								y: 0,
								opacity: 1
							});
						},
						offset: '90%'
					});
				}
			});
		}
	}
});

jQuery(window).load(function(){
	var text = jQuery('.home-top .intro .text-effect'),
		text_1 = text.find('.txt-1'),
		text_2 = text.find('.txt-2'),
		texts = text.find('.txt');

	var maxHeight = Math.max.apply(null, texts.map(function(){ return jQuery(this).height(); }).get());
	var maxWidth = Math.max.apply(null, texts.map(function(){ return jQuery(this).width(); }).get());

	text.height( maxHeight );
	text.width( maxWidth );
	text.animate({ opacity: 1 });
	tl1.play(0);

	homeActivity();
});

var resizeTimeout;
jQuery(window).resize(function(){
	clearTimeout(resizeTimeout);
	resizeTimeout = setTimeout(function(){
		homeActivity();
	}, 250);
});

var waypointTimeout;
function homeActivity(){
	jQuery('.activity-list .item .activity-detail').css({
		width: '',
		height: ''
	});

	if( !isBreakpoint('xs') ){
		jQuery('.activity-list .item .partner').imagesLoaded( function() {
			var maxWidth = Math.max.apply(null, jQuery('.activity-list .item .activity-detail').map(function(){ return jQuery(this).outerWidth(); }).get());
			jQuery('.activity-list .item .activity-detail').css({
				width: maxWidth
			});

			var maxHeight = Math.max.apply(null, jQuery('.activity-list .item .activity-detail').map(function(){ return jQuery(this).outerHeight(); }).get());
			jQuery('.activity-list .item .activity-detail').css({
				height: maxHeight
			});
		});
	}
	if( isBreakpoint('lg') ){
		clearTimeout(waypointTimeout);
		waypointTimeout = setTimeout(function(){
			Waypoint.refreshAll();
		}, 250);
	}
}
