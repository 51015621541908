jQuery(document).ready(function($) {
	if( isBreakpoint('sm') || isBreakpoint('md') || isBreakpoint('lg') ){
		// list page
		var productGrid = $('.our-works-page .works-list');
		productGrid.imagesLoaded( function() {
			var row_1 = productGrid.find('.row-layout-1').first();
			var row_2 = productGrid.find('.row-layout-2').first();

			// row-layout-1
			if( row_1.length && productGrid.find('.row-layout-1 .item-3').length ){
				productGrid.find('.row-layout-1 .item-3').css({
					marginTop: ( parseInt(row_1.find('.item-1').offset().top) + parseInt(row_1.find('.item-1').outerHeight()) ) - parseInt(row_1.find('.item-3').offset().top)
				});
			}

			// row-layout-2
			if( row_1.length && row_2.length && productGrid.find('.row-layout-2 .item-2').length ){
				productGrid.find('.row-layout-2 .item-2').css({
					marginTop: ( parseInt(row_1.find('.item-2').offset().top) + parseInt(row_1.find('.item-2').outerHeight()) ) - parseInt(row_2.find('.item-2').offset().top)
				});
			}

			if( productGrid.find('.row-layout-1').length>1 ){
				productGrid.find('.row-layout-1').css({
					marginTop: -1 * parseInt(productGrid.find('.row-layout-1 .item-1').css('paddingBottom'))
				});
				row_1.css({
					marginTop: 0
				});
			}


			$('.our-works-page .works-list .row-layout-1 .item-1').each(function(){
				if( !$(this).hasClass('animated') ){
					TweenMax.set($(this),{
						x: -200,
						opacity: 0
					});

					$(this).waypoint({
						handler: function(direction) {
							var $this = $(this.element);
							$this.addClass('animated');

							TweenMax.to($this, 1,{
								x: 0,
								opacity: 1
							});
						},
						offset: '90%'
					});
				}
			});
			$('.our-works-page .works-list .row-layout-1 .item-2').each(function(){
				if( !$(this).hasClass('animated') ){
					TweenMax.set($(this),{
						x: 100,
						opacity: 0
					});

					$(this).waypoint({
						handler: function(direction) {
							var $this = $(this.element);
							$this.addClass('animated');

							TweenMax.to($this, 1,{
								x: 0,
								opacity: 1
							});
						},
						offset: '90%'
					});
				}
			});
			$('.our-works-page .works-list .row-layout-1 .item-3').each(function(){
				if( !$(this).hasClass('animated') ){
					TweenMax.set($(this),{
						y: 100,
						opacity: 0
					});

					$(this).waypoint({
						handler: function(direction) {
							var $this = $(this.element);
							$this.addClass('animated');

							TweenMax.to($this, 1,{
								y: 0,
								opacity: 1
							});
						},
						offset: '90%'
					});
				}
			});


			$('.our-works-page .works-list .row-layout-2 .item-1').each(function(){
				if( !$(this).hasClass('animated') ){
					TweenMax.set($(this),{
						x: -200,
						opacity: 0
					});

					$(this).waypoint({
						handler: function(direction) {
							var $this = $(this.element);
							$this.addClass('animated');

							TweenMax.to($this, 1,{
								x: 0,
								opacity: 1
							});
						},
						offset: '90%'
					});
				}
			});
			$('.our-works-page .works-list .row-layout-2 .item-2').each(function(){
				if( !$(this).hasClass('animated') ){
					TweenMax.set($(this),{
						x: 100,
						opacity: 0
					});

					$(this).waypoint({
						handler: function(direction) {
							var $this = $(this.element);
							$this.addClass('animated');

							TweenMax.to($this, 1,{
								x: 0,
								opacity: 1
							});
						},
						offset: '90%'
					});
				}
			});
			$('.our-works-page .works-list .row-layout-2 .item-3').each(function(){
				if( !$(this).hasClass('animated') ){
					TweenMax.set($(this),{
						y: 100,
						opacity: 0
					});

					$(this).waypoint({
						handler: function(direction) {
							var $this = $(this.element);
							$this.addClass('animated');

							TweenMax.to($this, 1,{
								y: 0,
								opacity: 1
							});
						},
						offset: '90%'
					});
				}
			});
		});



		// detail page
		$('.project-numbers .number > span').counterUp({
			delay: 10,
			time: 1000
		});
		$('.project-numbers .numbers > div').each(function(){
			if( !$(this).hasClass('animated') ){
				TweenMax.set($(this),{
					x: -200,
					opacity: 0
				});

				$(this).waypoint({
					handler: function(direction) {
						var $this = $(this.element);
						$this.addClass('animated');

						TweenMax.to($this, 1,{
							x: 0,
							opacity: 1
						});
					},
					offset: '90%'
				});
			}
		});
	}

	var galleryFade = false;
	if( detectIE() ){
		$('.page-content .project-gallery>.container-fluid>.row>div .slick .item .device > img').each(function(){
			$(this).attr('src',$(this).attr('src').replace('.svg','.png'));
		});

		galleryFade = true;
	}

	$('.page-content .project-gallery>.container-fluid>.row>div .slick .item .device > img').imagesLoaded( function() {
		$('.page-content .project-gallery>.container-fluid>.row>div .slick .item .device').each(function(){
			$(this).find('> div').width( $(this).find('> img').width() );
			$(this).find('> div').height( $(this).find('> img').height() );
		});
	});

	$('.project-gallery .slick').slick({
		dots: true,
		arrows: false,
		autoplay: false,
		autoplaySpeed: 5000,
		pauseOnHover: false,
		infinite: true,
		draggable: true,
		fade:galleryFade
	});

	if( detectIE() ){
		$('.page-content .project-numbers>.container-fluid .background svg').hide();
		$('.page-content .project-numbers>.container-fluid .background .svg-line-fallback').removeClass('hidden');
	}
});

jQuery(window).resize(function(){
	jQuery('.page-content .project-gallery>.container-fluid>.row>div .slick .item .device').each(function(){
		jQuery(this).find('> div').width( jQuery(this).find('> img').width() );
		jQuery(this).find('> div').height( jQuery(this).find('> img').height() );
	});
});
