jQuery(document).ready(function($) {
	
	var teamSlick = $('.team-carousel .slick');
	teamSlick.on('init', function(){
		updateControls(0);
	});

	teamSlick.slick({
		dots: true,
		arrows: false,
		autoplay: false,
		autoplaySpeed: 5000,
		pauseOnHover: false,
		infinite: false,
		draggable: true,
		appendDots: $('.team-carousel .slide-controls'),
		slidesToShow: 1,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1.5
				}
			}
		]
	});

	teamSlick.on('afterChange', function(slick, currentSlide){
		updateControls('getFromSlick');
	});

	function updateControls(index){
		$('.team-carousel .slide-controls').each(function(){
			if( index=='getFromSlick' ){
				index = teamSlick.slick('slickCurrentSlide');
			}

			$(this).find('li').eq( index ).addClass('slick-active');
		});
	}

	if( isBreakpoint('sm') || isBreakpoint('md') || isBreakpoint('lg') ){
		$('.page-content .about-team .team-numbers>div>span').counterUp({
			delay: 10,
			time: 1000
		});
		$('.page-content .about-team .team-numbers>div.text-left').each(function(){
			if( !$(this).hasClass('animated') ){
				TweenMax.set($(this),{
					x: -200,
					opacity: 0
				});

				$(this).waypoint({
					handler: function(direction) {
						var $this = $(this.element);
						$this.addClass('animated');

						TweenMax.to($this, 1,{
							x: 0,
							opacity: 1
						});
					},
					offset: '90%'
				});
			}
		});
		$('.page-content .about-team .team-numbers>div.text-right').each(function(){
			if( !$(this).hasClass('animated') ){
				TweenMax.set($(this),{
					x: 200,
					opacity: 0
				});

				$(this).waypoint({
					handler: function(direction) {
						var $this = $(this.element);
						$this.addClass('animated');

						TweenMax.to($this, 1,{
							x: 0,
							opacity: 1
						});
					},
					offset: '90%'
				});
			}
		});
		$('.page-content .about-team .team-numbers>div.text-center').each(function(){
			if( !$(this).hasClass('animated') ){
				TweenMax.set($(this),{
					x: -200,
					opacity: 0
				});

				$(this).waypoint({
					handler: function(direction) {
						var $this = $(this.element);
						$this.addClass('animated');

						TweenMax.to($this, 1,{
							x: 0,
							opacity: 1
						});
					},
					offset: '90%'
				});
			}
		});
	}
});
