jQuery(document).ready(function($) {
	if( isBreakpoint('sm') || isBreakpoint('md') || isBreakpoint('lg') ){
		$('.landing-boxes>.container-fluid>.row>.row-height>div').each(function(index){
			if( !$(this).hasClass('animated') ){

				var yOffset = 100;
				if( index %2 == 1 ){
					yOffset = 200;
				}

				TweenMax.set($(this),{
					y: yOffset,
					opacity: 0
				});

				$(this).waypoint({
					handler: function(direction) {
						var $this = $(this.element);
						$this.addClass('animated');

						TweenMax.to($this, 1,{
							y: 0,
							opacity: 1
						});
					},
					offset: '90%'
				});
			}
		});

		$('.page-content .page-gallery>.row>.photo-1').each(function(index){
			if( !$(this).hasClass('animated') ){

				TweenMax.set($(this),{
					x: 200,
					opacity: 0
				});

				$(this).waypoint({
					handler: function(direction) {
						var $this = $(this.element);
						$this.addClass('animated');

						TweenMax.to($this, 1,{
							x: 0,
							opacity: 1
						});
					},
					offset: '90%'
				});
			}
		});

		$('.page-content .page-gallery>.row>.photo-2').each(function(index){
			if( !$(this).hasClass('animated') ){

				TweenMax.set($(this),{
					x: -100,
					opacity: 0
				});

				$(this).waypoint({
					handler: function(direction) {
						var $this = $(this.element);
						$this.addClass('animated');

						TweenMax.to($this, 1,{
							x: 0,
							opacity: 1
						});
					},
					offset: '90%'
				});
			}
		});

		$('.page-content .page-gallery>.row>.photo').each(function(index){
			if( !$(this).hasClass('animated') && !$(this).hasClass('photo-1') && !$(this).hasClass('photo-2') ){

				TweenMax.set($(this),{
					y: 100,
					opacity: 0
				});

				$(this).waypoint({
					handler: function(direction) {
						var $this = $(this.element);
						$this.addClass('animated');

						TweenMax.to($this, 1,{
							y: 0,
							opacity: 1
						});
					},
					offset: '90%'
				});
			}
		});
	}
});