jQuery(document).ready(function($) {
	if( isBreakpoint('sm') || isBreakpoint('md') || isBreakpoint('lg') ){
		$('.notes-list .item.image-left .post-info').each(function(index){
			if( !$(this).hasClass('animated') ){

				TweenMax.set($(this),{
					y: 200,
					x: -60,
					opacity: 0
				});

				$(this).waypoint({
					handler: function(direction) {
						var $this = $(this.element);
						$this.addClass('animated');

						TweenMax.to($this, 1,{
							y: 60,
							x: -60,
							opacity: 1
						});
					},
					offset: '90%'
				});
			}
		});

		$('.notes-list .item.image-right .post-info').each(function(index){
			if( !$(this).hasClass('animated') ){

				TweenMax.set($(this),{
					y: 200,
					x: 60,
					opacity: 0
				});

				$(this).waypoint({
					handler: function(direction) {
						var $this = $(this.element);
						$this.addClass('animated');

						TweenMax.to($this, 1,{
							y: 60,
							x: 60,
							opacity: 1
						});
					},
					offset: '90%'
				});
			}
		});

		$('.page-content .left-image .image').each(function(index){
			if( !$(this).hasClass('animated') ){

				TweenMax.set($(this),{
					x: -200,
					opacity: 0
				});

				$(this).waypoint({
					handler: function(direction) {
						var $this = $(this.element);
						$this.addClass('animated');

						TweenMax.to($this, 1,{
							x: 0,
							opacity: 1
						});
					},
					offset: '90%'
				});
			}
		});
	}
});