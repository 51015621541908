jQuery(document).ready(function($) {
	$('.nav-toggle').click(function(e){
		e.preventDefault();

		$('.header').toggleClass('open');
	});
	$('.nav-layer .dropdown-toggle').click(function(e){
		if( isBreakpoint('xs') ){
			e.preventDefault();

			if( $(this).find('.glyphicon').hasClass('glyphicon-chevron-right') ){
				$(this).find('.glyphicon').removeClass('glyphicon-chevron-right').addClass('glyphicon-chevron-down');
			}
			else{
				$(this).find('.glyphicon').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-right');
			}

			$(this).closest('.dropdown').toggleClass('open');
		}
	});


	if( detectIE() ){
		$('.page-top>.container-fluid .red-line svg').hide();
		$('.page-top>.container-fluid .red-line .svg-line-fallback').removeClass('hidden');
	}
});
